import React from "react";
import SEO from "../../components/atoms/seo";

import Layout from "../../components/organisms/layout";
import Heading from "../../components/molecules/heading";
import Title from "../../components/atoms/title";

const StyleguidePage = () => (
  <Layout>
    <SEO title="Styleguide" />
    <Heading title="Styleguide - Colors" weight="light" />
    <div className="p-styleguide">
      <Title type="h3" title="Colors" align="left" color="dark" weight="bold" />
      <Title type="h5" title="Gray" align="left" color="dark" weight="bold" />
      <div className="p-styleguide__colors p-styleguide__colors--gray">
        <div className="p-styleguide__color p-styleguide__color--100">100</div>
        <div className="p-styleguide__color p-styleguide__color--200">200</div>
        <div className="p-styleguide__color p-styleguide__color--300">300</div>
        <div className="p-styleguide__color p-styleguide__color--400">400</div>
        <div className="p-styleguide__color p-styleguide__color--500">500</div>
        <div className="p-styleguide__color p-styleguide__color--600">600</div>
        <div className="p-styleguide__color p-styleguide__color--700">700</div>
        <div className="p-styleguide__color p-styleguide__color--800">800</div>
        <div className="p-styleguide__color p-styleguide__color--900">900</div>
      </div>
      <Title
        type="h5"
        title="Primary"
        align="left"
        color="dark"
        weight="bold"
      />
      <div className="p-styleguide__colors p-styleguide__colors--primary">
        <div className="p-styleguide__color p-styleguide__color--100">100</div>
        <div className="p-styleguide__color p-styleguide__color--200">200</div>
        <div className="p-styleguide__color p-styleguide__color--300">300</div>
        <div className="p-styleguide__color p-styleguide__color--400">400</div>
        <div className="p-styleguide__color p-styleguide__color--500">500</div>
        <div className="p-styleguide__color p-styleguide__color--600">600</div>
        <div className="p-styleguide__color p-styleguide__color--700">700</div>
        <div className="p-styleguide__color p-styleguide__color--800">800</div>
        <div className="p-styleguide__color p-styleguide__color--900">900</div>
      </div>
    </div>
    <Title
      type="h5"
      title="Secondary"
      align="left"
      color="dark"
      weight="bold"
    />
    <div className="p-styleguide__colors p-styleguide__colors--secondary">
      <div className="p-styleguide__color p-styleguide__color--100">100</div>
      <div className="p-styleguide__color p-styleguide__color--200">200</div>
      <div className="p-styleguide__color p-styleguide__color--300">300</div>
      <div className="p-styleguide__color p-styleguide__color--400">400</div>
      <div className="p-styleguide__color p-styleguide__color--500">500</div>
      <div className="p-styleguide__color p-styleguide__color--600">600</div>
      <div className="p-styleguide__color p-styleguide__color--700">700</div>
      <div className="p-styleguide__color p-styleguide__color--800">800</div>
      <div className="p-styleguide__color p-styleguide__color--900">900</div>
    </div>
    <Title type="h5" title="Tertiary" align="left" color="dark" weight="bold" />
    <div className="p-styleguide__colors p-styleguide__colors--tertiary">
      <div className="p-styleguide__color p-styleguide__color--100">100</div>
      <div className="p-styleguide__color p-styleguide__color--200">200</div>
      <div className="p-styleguide__color p-styleguide__color--300">300</div>
      <div className="p-styleguide__color p-styleguide__color--400">400</div>
      <div className="p-styleguide__color p-styleguide__color--500">500</div>
      <div className="p-styleguide__color p-styleguide__color--600">600</div>
      <div className="p-styleguide__color p-styleguide__color--700">700</div>
      <div className="p-styleguide__color p-styleguide__color--800">800</div>
      <div className="p-styleguide__color p-styleguide__color--900">900</div>
    </div>
  </Layout>
);

export default StyleguidePage;
